<template>
  <div>
    <!-- <div class="custom-table-print mt-2 noprint">
      <b-button
        v-if="!disabled_redirect_btn"
        class="printer"
        size="sm"
        variant="none"
        :class="{ disabled: allows_crud }"
        :disabled="allows_crud"
        @click="redirectToSpecificationTable(evaluation_id, question_test_id)"
      >
        <template v-if="!allows_crud">
          <b-icon-printer></b-icon-printer>
          Vista Imprimible
        </template>
        <template v-else>
          <b-icon-printer></b-icon-printer>
          Vista Imprimible
        </template>
      </b-button>
    </div> -->
    <template v-if="!observation_table">
      <b-tabs v-model="tab_index">
        <b-tab>
          <template #title>
            <span :class="{ 'tab-title': tab_index == 1 && print_event }">
              <b-icon icon="card-text"></b-icon>
              Por Pregunta
            </span>
          </template>
          <div class="specification-table-content mt-2">
            <b-table-simple bordered small empty-text="no hay datos">
              <b-thead>
                <b-tr>
                  <b-th colspan="5" class="th-upper text-center py-1"
                    >Preguntas</b-th
                  >
                  <b-th colspan="3" class="th-upper text-center py-1">{{
                    $getVisibleNames(
                      "manual.criterio_de_evaluacion",
                      true,
                      "Criterios de Evaluación"
                    )
                  }}</b-th>
                </b-tr>
                <b-tr>
                  <b-th rowspan="2" class="text-center th-color-variant"
                    >N° Orden</b-th
                  >
                  <b-th rowspan="2" class="text-center th-color-variant"
                    >Tipo de Pregunta</b-th
                  >
                  <b-th rowspan="2" class="text-center th-color-variant"
                    >Sub-Tipo de Pregunta</b-th
                  >
                  <b-th
                    rowspan="2"
                    class="text-center align-middle th-color-variant"
                    >Puntaje</b-th
                  >
                  <b-th rowspan="2" class="text-center th-color-variant">
                    {{
                      $getVisibleNames(
                        "mesh.cognitivelevel",
                        false,
                        "Nivel Cognitivo"
                      )
                    }}
                  </b-th>
                  <b-th class="text-center align-middle th-color-variant"
                    >N°</b-th
                  >
                  <b-th
                    rowspan="2"
                    class="text-center align-middle th-color-variant"
                    >Enunciado</b-th
                  >
                  <b-th
                    rowspan="2"
                    class="text-center align-middle th-color-variant"
                    >Tipo</b-th
                  >
                  <b-th v-if="allows_crud && studyUnit">
                    <button-add
                      title="Agregar Item"
                      @click="
                        $bvModal.show(`modal-create-item-${question_test_id}`)
                      "
                    ></button-add>
                  </b-th>
                </b-tr>
              </b-thead>
              <!-- v-if="studyUnit" -->
              <b-tbody v-if="questions && questions.length > 0">
                <template v-for="item in evaluation_items">
                  <template
                    v-if="
                      getEvaluationCriterias(
                        item.evaluation_criterias_macro,
                        item.evaluation_criterias_micro
                      ).length > 0
                    "
                  >
                    <b-tr
                      v-for="(
                        evaluation_criteria, index
                      ) in getEvaluationCriterias(
                        item.evaluation_criterias_macro,
                        item.evaluation_criterias_micro
                      )"
                      :key="
                        'question' +
                        item.id +
                        'evaluation-criteria' +
                        evaluation_criteria.id
                      "
                    >
                      <b-td
                        class="text-center align-middle"
                        :rowspan="
                          getEvaluationCriterias(
                            item.evaluation_criterias_macro,
                            item.evaluation_criterias_micro
                          ).length > 0
                            ? getEvaluationCriterias(
                                item.evaluation_criterias_macro,
                                item.evaluation_criterias_micro
                              ).length
                            : 1
                        "
                        v-if="index == 0"
                      >
                        <div
                          v-if="allows_crud"
                          @click="
                            $bvModal.show(`edit-question-modal-${item.id}`)
                          "
                          class="edit-question-link"
                          v-b-tooltip.v-secondary.noninteractive="
                            'Editar Pregunta'
                          "
                        >
                          {{ item.order }}
                        </div>
                        <div v-else>
                          {{ item.order }}
                        </div>
                      </b-td>
                      <b-td
                        class="text-center align-middle"
                        :rowspan="
                          getEvaluationCriterias(
                            item.evaluation_criterias_macro,
                            item.evaluation_criterias_micro
                          ).length > 0
                            ? getEvaluationCriterias(
                                item.evaluation_criterias_macro,
                                item.evaluation_criterias_micro
                              ).length
                            : 1
                        "
                        v-if="index == 0"
                      >
                        <div
                          v-if="allows_crud"
                          @click="
                            $bvModal.show(`edit-question-modal-${item.id}`)
                          "
                          class="edit-question-link"
                          v-b-tooltip.v-secondary.noninteractive="
                            'Editar Pregunta'
                          "
                        >
                          {{ getQuestionType(item.question_type) }}
                        </div>
                        <div v-else>
                          {{ getQuestionType(item.question_type) }}
                        </div>
                        <b-modal
                          :id="`edit-question-modal-${item.id}`"
                          title="Editar Pregunta"
                          size="xl"
                          hide-footer
                        >
                          <QuestionForm
                            :Question="item"
                            :question_test_id="item.question_test"
                            :evaluation_id="evaluation_id"
                            @updated="slotUpdatedQuestion(item)"
                          ></QuestionForm>
                        </b-modal>
                      </b-td>
                      <b-td
                        class="text-center align-middle"
                        :rowspan="
                          getEvaluationCriterias(
                            item.evaluation_criterias_macro,
                            item.evaluation_criterias_micro
                          ).length > 0
                            ? getEvaluationCriterias(
                                item.evaluation_criterias_macro,
                                item.evaluation_criterias_micro
                              ).length
                            : 1
                        "
                        v-if="index == 0"
                      >
                        <span v-if="item.question_type == 1">
                          {{
                            item.max_answer_length != null ? "Breve" : "Extenso"
                          }}
                        </span>
                        <span v-else>{{
                          item.how_many_selected > 1 ? "Múltiple" : "Simple"
                        }}</span>
                      </b-td>
                      <b-td
                        class="text-center align-middle"
                        :rowspan="
                          getEvaluationCriterias(
                            item.evaluation_criterias_macro,
                            item.evaluation_criterias_micro
                          ).length > 0
                            ? getEvaluationCriterias(
                                item.evaluation_criterias_macro,
                                item.evaluation_criterias_micro
                              ).length
                            : 1
                        "
                        v-if="index == 0"
                      >
                        <div>
                          {{ item.score }}
                        </div>
                      </b-td>
                      <b-td
                        class="text-center align-middle"
                        :rowspan="
                          getEvaluationCriterias(
                            item.evaluation_criterias_macro,
                            item.evaluation_criterias_micro
                          ).length > 0
                            ? getEvaluationCriterias(
                                item.evaluation_criterias_macro,
                                item.evaluation_criterias_micro
                              ).length
                            : 1
                        "
                        v-if="index == 0"
                      >
                        <div v-if="item.cognitive_level" class="d-flex">
                          <span class="ml-1">
                            {{
                              getCognitiveLevel(item.cognitive_level)
                                ? getCognitiveLevel(item.cognitive_level)
                                    .level + "."
                                : ""
                            }}
                          </span>
                          <span class="ml-1">
                            {{
                              getCognitiveLevel(item.cognitive_level)
                                ? getCognitiveLevel(item.cognitive_level).name
                                : ""
                            }}
                          </span>
                        </div>
                        <div v-else>N/A</div>
                      </b-td>
                      <b-td class="text-center align-middle">
                        <template v-if="evaluation_criteria.study_unit">
                          <div
                            v-for="item in filterECMicro(evaluation_criteria)"
                            :key="item.id"
                            class="order-ec-micro"
                          >
                            {{ item.order + "."
                            }}{{ evaluation_criteria.order }}
                          </div>
                        </template>
                        <template v-else>
                          <div>{{ evaluation_criteria.order }}</div>
                        </template>
                      </b-td>
                      <b-td class="align-middle pt-1">
                        <template>
                          <div>
                            <SentenceContainer
                              :Sentence="evaluation_criteria"
                            ></SentenceContainer>
                          </div>
                        </template>
                      </b-td>

                      <b-td class="text-center align-middle">
                        {{ evaluation_criteria.study_unit ? "Micro" : "Macro" }}
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <b-tr :key="'question' + item.id">
                      <b-td class="text-center align-middle">
                        <div
                          v-if="allows_crud"
                          @click="
                            $bvModal.show(`edit-question-modal-${item.id}`)
                          "
                          class="edit-question-link"
                          v-b-tooltip.v-secondary.noninteractive="
                            'Editar Pregunta'
                          "
                        >
                          {{ item.order }}
                        </div>
                        <div v-else>
                          {{ item.order }}
                        </div>
                      </b-td>
                      <b-td class="text-center align-middle">
                        <div
                          v-if="allows_crud"
                          @click="
                            $bvModal.show(`edit-question-modal-${item.id}`)
                          "
                          class="edit-question-link"
                          v-b-tooltip.v-secondary.noninteractive="
                            'Editar Pregunta'
                          "
                        >
                          {{ getQuestionType(item.question_type) }}
                        </div>
                        <div v-else>
                          {{ getQuestionType(item.question_type) }}
                        </div>
                      </b-td>
                      <b-modal
                        :id="`edit-question-modal-${item.id}`"
                        title="Editar Pregunta"
                        size="xl"
                        hide-footer
                      >
                        <QuestionForm
                          :Question="item"
                          :question_test_id="item.question_test"
                          :evaluation_id="evaluation_id"
                          @updated="slotUpdatedQuestion(item)"
                        ></QuestionForm>
                      </b-modal>
                      <b-td class="text-center align-middle">
                        <span v-if="item.question_type == 1">
                          {{
                            item.max_answer_length != null ? "Extenso" : "Breve"
                          }}
                        </span>
                        <span v-else>{{
                          item.how_many_selected > 1 ? "Múltiple" : "Simple"
                        }}</span>
                      </b-td>
                      <b-td class="text-center align-middle">
                        <div>
                          {{ item.score }}
                        </div>
                      </b-td>
                      <b-td class="text-center align-middle">
                        <div v-if="item.cognitive_level">
                          {{
                            getCognitiveLevel(item.cognitive_level)
                              ? getCognitiveLevel(item.cognitive_level).level +
                                ". " +
                                getCognitiveLevel(item.cognitive_level).name
                              : ""
                          }}
                        </div>
                        <div v-else>N/A</div>
                      </b-td>
                      <b-td class="text-center align-middle">-</b-td>
                      <b-td class="text-center align-middle">
                        <div>N/A</div>
                      </b-td>

                      <b-td class="text-center align-middle"
                        ><div>N/A</div></b-td
                      >
                    </b-tr>
                  </template>
                </template>
              </b-tbody>
              <template v-else>
                <b-td colspan="8" class="empty-instrument-alert">
                  <b-icon icon="exclamation-circle"></b-icon> El instrumento
                  actual no tiene preguntas creadas.
                </b-td>
              </template>
            </b-table-simple>
          </div>
        </b-tab>
        <!-- ############################## Por Criterio ############################## -->
        <b-tab>
          <template #title>
            <span :class="{ 'tab-title': tab_index == 0 && print_event }">
              <b-icon icon="list-check"></b-icon> Por Criterio
            </span>
          </template>
          <b-table-simple bordered small class="mt-3">
            <b-thead>
              <b-tr>
                <b-th colspan="3" class="th-upper text-center w-75">{{
                  $getVisibleNames(
                    "manual.criterio_de_evaluacion",
                    true,
                    "Criterios de Evaluación"
                  )
                }}</b-th>
                <b-th colspan="3" class="th-upper text-center">Preguntas</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center th-color-variant">N°</b-th>
                <b-th rowspan="2" class="text-center th-color-variant"
                  >Enunciado</b-th
                >
                <b-th rowspan="2" class="text-center th-color-variant"
                  >Tipo</b-th
                >
                <b-th rowspan="2" class="text-center th-color-variant"
                  >Cantidad</b-th
                >
                <b-th rowspan="2" class="text-center th-color-variant">
                  Tipo de Pregunta
                </b-th>
                <b-th rowspan="2" class="text-center th-color-variant">
                  N° Orden
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="questions && questions.length > 0">
              <template
                v-for="(
                  evaluation_criteria, index
                ) in tableEvaluationCriterias()"
              >
                <template>
                  <b-tr
                    v-for="(item, index2) in evaluationCriteriaQuestion(
                      evaluation_criteria
                    )"
                    :key="'question' + index2 + 'evaluation-criteria' + index"
                  >
                    <b-td
                      class="text-center align-middle"
                      :rowspan="
                        evaluationCriteriaQuestion(evaluation_criteria).length
                      "
                      v-if="index2 == 0"
                      ><template v-if="evaluation_criteria.study_unit">
                        <div
                          v-for="item in filterECMicro(evaluation_criteria)"
                          :key="item.id"
                          class="order-ec-micro"
                        >
                          {{ item.order + "." }}{{ evaluation_criteria.order }}
                        </div>
                      </template>
                      <template v-else>
                        <div>{{ evaluation_criteria.order }}</div>
                      </template>
                    </b-td>
                    <b-td
                      class="align-middle"
                      :rowspan="
                        evaluationCriteriaQuestion(evaluation_criteria).length
                      "
                      v-if="index2 == 0"
                    >
                      <template>
                        <div>
                          <SentenceContainer
                            :Sentence="evaluation_criteria"
                          ></SentenceContainer>
                        </div>
                      </template>
                    </b-td>

                    <b-td
                      class="text-center align-middle"
                      :rowspan="
                        evaluationCriteriaQuestion(evaluation_criteria).length
                      "
                      v-if="index2 == 0"
                    >
                      {{ evaluation_criteria.study_unit ? "Micro" : "Macro" }}
                    </b-td>
                    <b-td
                      class="text-center align-middle"
                      :rowspan="
                        evaluationCriteriaQuestion(evaluation_criteria).length
                      "
                      v-if="index2 == 0"
                    >
                      {{
                        evaluationCriteriaQuestion(evaluation_criteria).length
                      }}
                    </b-td>
                    <b-td class="text-center align-middle">
                      <div
                        v-if="allows_crud"
                        @click="$bvModal.show(`edit-question-modal-${item.id}`)"
                        class="edit-question-link"
                        v-b-tooltip.v-secondary.noninteractive="
                          'Editar Pregunta'
                        "
                      >
                        {{ getQuestionType(item.question_type) }}
                      </div>
                      <div v-else>
                        {{ getQuestionType(item.question_type) }}
                      </div>
                    </b-td>
                    <b-td class="text-center align-middle">
                      <div
                        v-if="allows_crud"
                        @click="
                          $bvModal.show(
                            `edit-criteria-question-${item.id}-modal-${index}`
                          )
                        "
                        class="edit-question-link"
                        v-b-tooltip.v-secondary.noninteractive="
                          'Editar Pregunta'
                        "
                      >
                        {{ item.order }}
                      </div>
                      <div v-else>
                        {{ item.order }}
                      </div>
                      <b-modal
                        :id="`edit-criteria-question-${item.id}-modal-${index}`"
                        title="Editar Pregunta"
                        size="xl"
                        hide-footer
                      >
                        <QuestionForm
                          :Question="item"
                          :question_test_id="item.question_test"
                          :evaluation_id="evaluation_id"
                          @updated="slotUpdatedQuestion(item, index)"
                        ></QuestionForm>
                      </b-modal>
                    </b-td>
                  </b-tr>
                </template>
              </template>
              <b-tr
                v-for="(
                  empty_criteria_question, index
                ) in questionWithoutCriteria()"
                :key="index"
              >
                <b-td
                  class="text-center align-middle"
                  :rowspan="questionWithoutCriteria().length"
                  v-if="index == 0"
                  >-</b-td
                >
                <b-td
                  class="text-center align-middle"
                  :rowspan="questionWithoutCriteria().length"
                  v-if="index == 0"
                  >N/A</b-td
                >
                <b-td
                  class="text-center align-middle"
                  :rowspan="questionWithoutCriteria().length"
                  v-if="index == 0"
                  >N/A</b-td
                >
                <b-td
                  class="text-center align-middle"
                  :rowspan="questionWithoutCriteria().length"
                  v-if="index == 0"
                  >{{ questionWithoutCriteria().length }}</b-td
                >
                <b-td class="text-center align-middle">
                  <div
                    v-if="allows_crud"
                    @click="
                      $bvModal.show(
                        `edit-criteria-question-${empty_criteria_question.id}-modal-${index}`
                      )
                    "
                    class="edit-question-link"
                    v-b-tooltip.v-secondary.noninteractive="'Editar Pregunta'"
                  >
                    {{ getQuestionType(empty_criteria_question.question_type) }}
                  </div>
                  <div v-else>
                    {{ getQuestionType(empty_criteria_question.question_type) }}
                  </div>
                </b-td>
                <b-td class="text-center align-middle">
                  <div
                    v-if="allows_crud"
                    @click="
                      $bvModal.show(
                        `edit-criteria-question-${empty_criteria_question.id}-modal-${index}`
                      )
                    "
                    class="edit-question-link"
                    v-b-tooltip.v-secondary.noninteractive="'Editar Pregunta'"
                  >
                    {{ empty_criteria_question.order }}
                  </div>
                  <div v-else>
                    {{ empty_criteria_question.order }}
                  </div>
                  <b-modal
                    :id="`edit-criteria-question-${empty_criteria_question.id}-modal-${index}`"
                    title="Editar Pregunta"
                    size="xl"
                    hide-footer
                  >
                    <QuestionForm
                      :Question="empty_criteria_question"
                      :question_test_id="empty_criteria_question.question_test"
                      :evaluation_id="evaluation_id"
                      @updated="
                        slotUpdatedQuestion(empty_criteria_question, index)
                      "
                    ></QuestionForm>
                  </b-modal>
                </b-td>
              </b-tr>
            </b-tbody>
            <template v-else>
              <b-td colspan="6" class="empty-instrument-alert">
                <b-icon icon="exclamation-circle"></b-icon> El instrumento
                actual no tiene preguntas creadas.
              </b-td>
            </template>
          </b-table-simple>
        </b-tab>
        <!-- ############################## Por Nivel Cognitivo ############################## -->
        <b-tab>
          <template #title>
            <span :class="{ 'tab-title': tab_index == 0 && print_event }">
              <b-icon icon="list-ol"></b-icon> Por Nivel Cognitivo
            </span>
          </template>
          <CognitiveLevelST
            :questions="questions"
            :cognitive_levels="cognitive_levels"
            :observation_table="observation_table"
            :evaluation_id="evaluation_id"
            :allows_crud="allows_crud"
            :evaluation_criterias="tableEvaluationCriterias"
            @updatedQuestion="slotUpdatedQuestion"
          >
          </CognitiveLevelST>
        </b-tab>
      </b-tabs>
    </template>
    <template v-else>
      <b-tabs>
        <b-tab>
          <template #title>
            <span :class="{ 'tab-title': tab_index == 0 && print_event }">
              <b-icon icon="list-ol"></b-icon> Por Nivel Cognitivo
            </span>
          </template>
          <CognitiveLevelDimensionsST
            :dimensions="questions"
            :cognitive_levels="cognitive_levels"
            :observation_table="observation_table"
            :evaluation_id="evaluation_id"
            :allows_crud="allows_crud"
            :evaluation_criterias="tableEvaluationCriterias"
            @updatedDimension="slotUpdatedDimension"
          >
          </CognitiveLevelDimensionsST>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "SpecificationTable",
  components: {
    // ItemRow: () => import("./ItemRow"),
    QuestionForm: () => import("../Test/QuestionForm.vue"),
    CognitiveLevelST: () => import("./CognitiveLevelST.vue"),
    CognitiveLevelDimensionsST: () =>
      import("./CognitiveLevelDimensionsST.vue"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    question_test_id: {
      type: Number,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    print_event: {
      type: Boolean,
      default: false,
    },
    observation_table: {
      type: Boolean,
      default: false,
    },
    disabled_redirect_btn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      evaluation_items: [],
      cognitive_levels: [],
      tab_index: 0,
      print_view: false,
    };
  },
  computed: {
    ...mapGetters({
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      evaluations: names.EVALUATIONS,
      procedureEvidences: names.PROCEDURE_EVIDENCES,
      questionTypes: names.QUESTION_TYPES,
      allQuestions: names.QUESTIONS,
      allObservationInstances: names.OBSERVATION_INSTANCES,
      questionTests: names.QUESTION_TESTS,
      observationInstruments: names.OBSERVATION_INSTRUMENTS,
      section_evaluations: names.SECTION_EVALUATIONS,
    }),
    questionTest() {
      return this.questionTests.find((x) => x.id == this.question_test_id);
    },
    observationInstrument() {
      return this.observationInstruments.find(
        (x) => x.id == this.question_test_id
      );
    },
    evaluation() {
      if (!this.questionTest) return null;
      return this.evaluations.find((x) => x.id == this.questionTest.evaluation);
    },
    questions() {
      if (!this.observation_table) {
        if (!this.questionTest) return [];
        return this.allQuestions.filter(
          (x) => x.question_test == this.questionTest.id
        );
      } else {
        if (!this.observationInstrument) return [];
        return this.allObservationInstances.filter(
          (x) => x.observation_instrument == this.observationInstrument.id
        );
      }
    },
    evaluationCriteriasMacrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_macro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMacros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    evaluationCriteriasMicrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_micro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMicros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filter_section_evaluation() {
      return this.section_evaluations.filter(
        (x) => x.evaluation == this.evaluation_id
      );
    },
    studyUnit() {
      if (!this.evaluation) return null;
      return this.studyUnits.find((x) => x.id == this.evaluation.study_unit);
    },
    itemsList() {
      let list = [];
      this.evaluation_items.forEach((element) => {
        let evaluation_criteria = this.evaluationCriterias.find(
          (x) => x.id == element.evaluation_criteria
        );
        let performance_indicator = this.performanceIndicators.find(
          (x) => x.id == element.performance_indicator
        );
        list.push({
          item: element,
          evaluation_criteria: evaluation_criteria,
          performance_indicator: performance_indicator,
        });
      });
      return list;
    },
    remainingWeighing() {
      let count = 0;
      this.evaluation_items.forEach((element) => {
        count += element.weighing;
      });
      return Math.max(100 - count, 0);
    },
    totalWeighing() {
      let count = 0;
      this.evaluation_items.forEach((element) => {
        count += element.weighing;
      });
      return count;
    },
    evaluationCriteriaMicroWeighing() {
      let count = 0;
      this.evaluationCriteriasMicrosFilter.forEach((element) => {
        count += element.weighing;
      });
      return count;
    },
    evaluationCriteriaMacroWeighing() {
      let count = 0;
      this.evaluationCriteriasMacrosFilter.forEach((element) => {
        count += element.weighing;
      });
      return count;
    },
  },
  methods: {
    getEvaluationCriterias(
      evaluation_criterias_macro,
      evaluation_criterias_micro
    ) {
      let evaluation_criterias_list = [];
      this.evaluationCriteriasMacrosFilter.forEach((element) => {
        if (evaluation_criterias_macro.includes(element.id)) {
          evaluation_criterias_list.push(element);
        }
      });
      this.evaluationCriteriasMicrosFilter.forEach((element) => {
        if (evaluation_criterias_micro.includes(element.id)) {
          evaluation_criterias_list.push(element);
        }
      });
      return evaluation_criterias_list;
    },
    filterECMicro(evaluation_criteria) {
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias =
          this.evaluationCriteriasMicrosFilter.filter(
            (x) =>
              x.study_unit == study_unit.id &&
              x.study_unit == evaluation_criteria.study_unit
          );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
          });
      });
      return list;
    },
    tableEvaluationCriterias() {
      let ec_list_macro = [];
      let ec_list_micro = [];
      this.evaluation_items.forEach((item) => {
        let list_macro = this.getEvaluationCriterias(
          item.evaluation_criterias_macro,
          []
        ).map((object) => object.id);
        let list_micro = this.getEvaluationCriterias(
          [],
          item.evaluation_criterias_micro
        ).map((object) => object.id);
        ec_list_macro = ec_list_macro.concat(list_macro);
        ec_list_micro = ec_list_micro.concat(list_micro);
      });
      ec_list_macro = [...new Set(ec_list_macro)];
      ec_list_micro = [...new Set(ec_list_micro)];
      let ec_macro_list = [];
      let ec_micro_list = [];
      this.evaluationCriteriasMacrosFilter.forEach((element) => {
        if (ec_list_macro.includes(element.id)) {
          ec_macro_list.push(element);
        }
      });
      this.evaluationCriteriasMicrosFilter.forEach((element) => {
        if (ec_list_micro.includes(element.id)) {
          ec_micro_list.push(element);
        }
      });
      let ec_list = ec_macro_list.concat(ec_micro_list);
      return ec_list;
    },
    evaluationCriteriaQuestion(evaluation_criteria) {
      let list = [];
      if (evaluation_criteria.study_unit) {
        list = this.evaluation_items.filter((x) =>
          x.evaluation_criterias_micro.includes(evaluation_criteria.id)
        );
      } else {
        list = this.evaluation_items.filter((x) =>
          x.evaluation_criterias_macro.includes(evaluation_criteria.id)
        );
      }
      return list;
    },
    questionWithoutCriteria() {
      return this.evaluation_items.filter(
        (x) =>
          x.evaluation_criterias_macro.length == 0 &&
          x.evaluation_criterias_micro.length == 0
      );
    },
    getCognitiveLevel(cognitive_level_id) {
      let cognitive_level = this.cognitive_levels.find(
        (x) => x.id == cognitive_level_id
      );
      return cognitive_level ? cognitive_level : null;
    },
    getQuestionType(question_type) {
      let get_question_type = this.questionTypes.find(
        (x) => x.id == question_type
      );
      return get_question_type ? get_question_type.value : "";
    },
    fetchCognitiveLevels() {
      this.$restful.Get("/mesh/cognitive-level/").then((response) => {
        this.cognitive_levels = response;
      });
    },
    patchQuestionScore(question) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const payload = {
          question_id: question.id,
          item: question,
        };
        if (question.question_type == 2) {
          this.$store
            .dispatch(names.PATCH_MULTIPLE_CHOICE_QUESTION, payload)
            .then((response) => {
              if (response) {
                this.$emit("multipleChoiceScoreChanged", response);
                toast("Pregunta actualizada.");
              }
            });
        } else {
          this.$store
            .dispatch(names.PATCH_QUESTION, payload)
            .then((response) => {
              if (response) {
                this.$emit("redactionScoreChanged", response);
                toast("Pregunta actualizada.");
              }
            });
        }
      }, 1000);
    },
    slotUpdatedQuestion(item, index) {
      this.$emit("specificationTableQuestion", item);
      this.$bvModal.hide(`edit-question-modal-${item.id}`);
      this.$bvModal.hide(`edit-criteria-question-${item.id}-modal-${index}`);
      this.$bvModal.hide(`edit-cl-question-${item.id}`);
    },
    slotUpdatedDimension(item) {
      this.$emit("specificationTableDimension", item);
      this.$bvModal.hide(`edit-cl-dimension-${item.id}`);
    },

    redirectToSpecificationTable(evaluation_id, question_test_id) {
      this.print_view = !this.print_view;
      this.$emit("printViewMode", this.print_view);
      this.$router.push({
        name: "SpecificationTableView",
        params: {
          evaluation_id: evaluation_id,
          question_test_id: question_test_id,
        },
      });
      // setTimeout(() => {

      //   const route = this.$router.push({
      //     name: "SpecificationTableView",
      //     params: {
      //       evaluation_id: evaluation_id,
      //       question_test_id: question_test_id,
      //     },
      //   });
      //   window.open(route.href, "_blank");
      // }, 1300);
    },
  },
  watch: {
    questions: function () {
      this.evaluation_items = this.questions;
    },
  },
  created() {
    this.fetchCognitiveLevels();
    if (!this.observation_table) {
      this.$store
        .dispatch(names.FETCH_QUESTION_TEST, this.question_test_id)
        .then((response) => {
          this.$store.dispatch(names.FETCH_EVALUATION, response.evaluation);
        });
    } else {
      this.$store
        .dispatch(names.FETCH_OBSERVATION_INSTRUMENT, this.question_test_id)
        .then((response) => {
          this.$store.dispatch(names.FETCH_EVALUATION, response.evaluation);
        });
    }
    this.$store
      .dispatch(names.FETCH_QUESTIONS, this.question_test_id)
      .then(() => {
        this.$store.dispatch(
          names.FETCH_MULTIPLE_CHOICE_QUESTIONS,
          this.question_test_id
        );
      });
  },
  mounted() {
    if (this.observation_table) {
      this.evaluation_items = this.questions;
    }
  },
};
</script>

<style scoped>
.specification-table-content {
  overflow: auto;
}
.custom-table-print {
  display: flex;
  /* margin-top: -1.8% !important; */
  margin-bottom: -1.8% !important;
}
.printer {
  background: var(--kl-thirth-color) !important;
  color: var(--secondary-font-color);
  margin-left: auto;
  right: 0 !important;
}
.printer:hover {
  background: var(--kl-thirth-hover-color) !important;
}
.disabled {
  opacity: 0.35;
}
.disabled:hover {
  background: var(--kl-thirth-color) !important;
}
.tab-title {
  display: none !important;
}
table {
  /* border: 1px solid black; */
  width: 100%;
}
th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-size: var(--secondary-font-size);
  /* border: 1px solid black; */
  padding: 0 4px;
}
.th-color-variant {
  background-color: var(--primary-hover-color) !important;
}
.th-upper {
  background-color: var(--primary-color) !important;
}
td {
  padding: 2px 4px;
}
.order-ec-micro {
  display: flex;
}
.input-score {
  max-width: 3.2em;
  padding: 0 0.4em;
}
.edit-question-link {
  color: #007bff;
}
.edit-question-link:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
tfoot {
  background-color: var(--secondary-color);
}
.secondary-header {
  background-color: var(--secondary-color);
}
.test-container {
  text-align: center;
}
.empty-instrument-alert {
  text-align: center;
  padding: 2em 0;
}
</style>